*{
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
    box-sizing: border-box;
}
body {
  font-family: Arial, sans-serif;
}
.wrapper{
	width: 100%;
	max-width: 750px;
	margin: 20px auto;
	padding: 0px 0px;	
}
.meeting_list_page{
    padding: 130px 0 100px;
}
.my_request_main h2, .request_callback h2{
    font-size: 18px;
    color: #000;
    border-bottom: 1px solid #8abed4;
    display: inline-block;
    padding-bottom: 3px;
    margin-bottom: 0px;
}
.my_request_main , .request_callback {
    float: left;
    width: 50%;
    padding: 0px 20px;

}
.clear{
	clear: both;
}
.my_request_boxes{
	margin: 20px 0px 20px;
}
.my_request_boxes .img_sec{
	position: relative;
}
.my_request_boxes .img_sec .details_dot {
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
}
.details_contact {
    position: absolute;
    right: 0;
    top: 5px;
}
.details_contact ul li{
	display: inline-block;
	list-style: none;
	margin: 0px 10px;
}
.my_request_boxes .img_sec .details_dot img {
    width: 20px;		
}
.my_request_boxes .img_sec .img{
	float: left;
    width: 52px;
}
.my_request_boxes .img_sec .cont{
	float: left;
	width: 84%;
	padding: 7px 20px 0;
}
.my_request_boxes .img_sec .cont h3{
    font-size: 15px;
    color: #050505;
}
.my_request_boxes span.status {
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
}
.my_request_boxes .span_meta{
	font-size: 14px;
	color: #44b1c5;
	margin-top: 10px;
}
.my_request_boxes .span_meta .time{
	
}
.my_request_boxes .span_meta .date{
	
}
.my_request_boxes .content_para{
    font-size: 14px;
    color: #050505;	
    margin-top: 10px;

}



@media screen and (max-width: 767px){
	.my_request_main, .request_callback {
	    float: none;
	    width: 100%;
	    padding: 0px 20px;
    }	
    .meeting_list_page{
        padding: 100px 0 100px;
    }
}


.my_request_main,.request_callback {
    height: 446px;
    max-height: 100%;
    overflow-y: auto;
}







/* new css */

.mpac_wrapper {
    margin: 180px 0 150px 0;
    padding-bottom: 200px;
}

.circle-tick-mark {
    font-size: 28px;
}

.clear{
	clear: both;
}
.mpac_search_form label{
	color: #4d4d4d;
	font-size: 14px; 
	display: block;
}
.mpac_search_form input[type="text"],
.mpac_search_form input[type="number"]{
	border: 1px solid #d9d9d9;
    font-size: 15px;
    color: #000;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    margin-top: 5px;
    padding: 0px 20px;
    position: relative;
    text-decoration: none;
}
.mpac_search_form input[type="submit"], .mpac_listing_anchor{
    background-color: #00b0e9;
    color: #fff;
    padding: 14px 20px;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    height: 50px;
    font-size: 19px;
    border-radius: 5px;
    text-decoration: none;
    line-height: normal;
}
.mpac_listing_anchor_skip{
    background-color: #c6c6c8;
    color: #fff;
    padding: 14px 20px;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    height: 50px;
    font-size: 19px;
    border-radius: 5px;
    text-decoration: none;
    line-height: normal;
}
.mpac_search_form .form-group{
	margin: 15px 0;
	position: relative;
}
.mpac_street_search{
	padding-left: 50px !important;
}
.mpac_search_icon{
    position: absolute;
    z-index: 9;
    width: 20px;
    left: 15px;
    top: 18px;
}

.mpac_listing h2{
    clear: both;
    display: block;
    width: 100%;
    font-size: 24px;
    margin-bottom: 10px;
    color: #000;	
}
.mpac_listing h2 img{
	width: 50px;
    object-fit: cover;
    object-position: center;
    text-align: right;
    display: inline-block;
    float: right;    
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #f8f8f9;
    border-bottom: 1px solid #e0e0e2;
    padding: 10px;
    border-radius: 5px;
    margin: 15px 0;
}
.media img.mr-3{
    width: 50px;
    height: 50px;
    margin-right: 1rem!important;
    object-fit: none;
}
.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.media-body h3{
    clear: both;
    display: block;
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
    color: #000;
}
.media-body h3 a {
    text-align: right;
    float: right;
    color: #0ca4d6;
    font-size: 16px;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
}

.media-body p{
    position: relative;
    clear: both;
    margin-bottom: 10px;
    font-size: 16px;
    color: #777777;	
}
.media-body p + a{
    color: #0ca4d6;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
}
.mpac_listing h4{
    text-align: center;
    margin: 50px 0px;
    font-size: 30px;
    color: #000;
}

.media input[type="checkbox"]{
	width: 30px;
	height: 30px;
	margin-right: 1rem!important;
}
.mpax_list {
	margin-bottom: 30px;
}

.main_inner {
    display: flex;
    justify-content: space-between;
}
.main_inner div{
    padding: 0px 10px;
    margin: 10px 0;
    display: block;
    font-size: 17px;
}
.main_inner.total {
    border-top: 1px solid #909090;
}
















@media screen and (max-width: 767px){
	.my_request_main, .request_callback {
	    float: none;
	    width: 100%;
	    padding: 0px 20px;
	}	
}

.mpac-circle-select {
    color: #00b0e9;
}





