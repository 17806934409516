body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Regular !important;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.owl-carousel.pxp-props-carousel-right-stage-2
  li.alice-carousel__stage-item.__active {
  width: 100px !important;
}

/* .neighbourhoods_slider{
  width: 475px;
} */

/* .neighbourhoods_slider .pxp-prop-card-explore{
  width: 445px;
} */

.post-navbar-items {
  margin-right: 20px;
}
.post-navbar {
  font-size: 30px;
  padding: 5px 15px;
  margin-top: 20px;
}

.vendor-navbar {
  font-size: 30px;
  padding: 0px;
}

.navWrap {
  padding: 10px 0px;
  margin: 10px 0px;
  position: relative;
}

.navWrap:after {
  content: "";
  position: absolute;
  left: -100%;
  right: -100%;
  bottom: 0px;
  height: 1px;
  background: #f5f5f5;
}
.navWrap:before {
  content: "";
  position: absolute;
  left: -100%;
  right: -100%;
  top: 0px;
  height: 1px;
  background: #f5f5f5;
}

.modal-header {
  border-bottom: none;
  padding: 15px 15px 0px 15px;
}
.modal-body {
  padding: 15px;
}
.logo-signupModal {
  width: 100px;
}
.text-center {
  color: #3f3f3f;
}

.signupCards {
  height: 165px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ededf2;
  margin-bottom: 10px;
  padding: 10px;
}
.subscription-card {
  background: #fff;
  padding: 20px 10px;
}

.infoWindowStyle:after {
  content: " ";
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  margin-left: -5px;
  width: 9px;
  height: 9px;
  background: #fff;
  border-right: 2px solid #333;
  border-bottom: 2px solid #333;
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.card-selection {
  padding: 20px 10px;
}
/* .vendor-img{
  max-width: 70px;
  max-height: 70px;
} */
.card {
  font-size: 17px;
  color: #c8c8c8;
  border: 0px;
}
.card-description {
  font-size: 17px;
  color: #333;
}
.user {
  font-family: "Regular", sans-serif;
  text-transform: uppercase;
  font-size: 16px !important;
  color: #000;
  padding: 8px 0 6px 0;
  font-weight: bold;
}

.vendor-login-name {
  color: black;
  /* float: right;
  margin-left: 5px !important;
  margin-top: 10px; */
  cursor: pointer;
  display: block;
  font-size: 14px;
  /* padding-right: 10px;
  margin-top: -8px; */
}

.pxp-full .vendor-login-name {
  color: #000;
}

.user-description {
  font-family: "Condensed", sans-serif;
  font-size: 14px;
  color: #000;
}
.card-btn {
  width: 100%;
  border-radius: 10px;
  padding: 23px;
  margin-top: 15px;
}
.dashed-button {
  text-align: center;
  border: 1px dashed #00b0e9;
  padding: 9px;
  border-radius: 10px;
}
.pxp-prop-card-1-fig .pxp-cover {
  width: 290px;
}

.country-code {
  position: absolute !important;
  bottom: 159px;
  margin-left: 30px !important;
  color: #000 !important;
  margin-right: 4px;
}
.country-image-login-page {
  position: absolute !important;
  bottom: 36px;
  margin-left: 32px !important;
  color: #000 !important;
}
.country-image-contact-page {
  position: absolute !important;
  top: 136px;
  margin-left: 30px !important;
  color: #000 !important;
}
.country-code-login-page {
  position: absolute !important;
  bottom: 228.5px;
  margin-left: 30px !important;
  color: #000 !important;
  padding-left: 10px;
}
.country-code-forgot-page {
  position: absolute !important;
  bottom: 101px;
  margin-left: 30px !important;
  color: #000 !important;
  padding-left: 10px;
}
.country-code-user-page {
  position: absolute !important;
  bottom: 123.5px;
  margin-left: 30px !important;
  color: #000 !important;
  padding-left: 10px;
}
.phone-number {
  display: block;
  width: 100%;
  padding: 0.5rem 3.4rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.profile-pic {
  border: 1px solid black;
  border-radius: 50px;
  height: 100px;
  width: 100px;
}
.logo-modal {
  text-align: -webkit-center;
}

.subscription-card .logo-modal {
  text-align: left;
}
.subscription-card .logo-modal img {
  width: auto;
}

.signin-popup {
  width: 400px !important;
}

.modal-width {
  width: 385px;
}
.country-code-forgotPass {
  position: absolute !important;
  bottom: 142px;
  margin-left: 30px !important;
  color: #000 !important;
}
.drop-down {
  height: 46px;
}
.input-file,
.input-file input[type="file"],
.input-file input[type="file"] + label,
.input-file,
.input-file input[type="text"],
.input-file input[type="text"] + label {
  position: relative;
}
.input-file {
  border: 1px solid #d9d9d9;
}
.input-file input[type="file"],
.input-file input[type="date"] {
  font-size: 0;
  border: 0;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.input-file input[type="file"] + label,
.input-file input[type="date"] + label {
  border: none;
  color: #878f97;
  cursor: pointer;
  display: block;
  font-size: 14px;
  /*font-weight: 500;
  */
  outline: none;
  padding: 10px 10px 8px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.input-file .fa {
  text-align: right;
  float: right;
  position: relative;
  top: 2px;
  font-size: 20px;
}

.profile-pic-professional {
  height: 100px;
  width: 100px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.profile-pic-user {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.input-file input[type="file"] + label.btn-2:before,
.input-file input[type="date"] + label.btn-2:before {
  color: #fff;
  content: "\f382";
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  right: 20%;
  position: absolute;
  top: 0px;
  transition: all 0.3s;
}
.logo-modal .image-upload-container {
  display: grid;
  grid-template-areas: "img-u-one img-u-two img-u-three img-u-four img-u-five img-u-six";
}
.logo-modal .center {
  display: inline;
  margin: 3px;
}

.logo-modal .form-input {
  width: 100px;
  padding: 3px;
  background: #fff;
  border: 2px dashed dodgerblue;
}
.logo-modal .form-input input {
  display: none;
}
.logo-modal .form-input label {
  display: block;
  width: 100px;
  height: auto;
  max-height: 100px;
  background: #333;
  border-radius: 10px;
  cursor: pointer;
}

.logo-modal .form-input img {
  width: 100px;
  height: 100px;
  margin: 2px;
  opacity: 0.4;
}

.logo-modal .imgRemove {
  position: relative;
  bottom: 114px;
  left: 68%;
  background-color: transparent;
  border: none;
  font-size: 30px;
  outline: none;
}
.logo-modal .imgRemove::after {
  content: " \21BA";
  color: #fff;
  /* font-weight: 900; */
  border-radius: 8px;
  cursor: pointer;
}

.logo-modal .small {
  color: #fff;
}

.logo-modal .image-upload-one {
  grid-area: img-u-one;
  display: flex;
  justify-content: center;
}

.add-property-conatiner {
  margin-top: 70px;
  padding: 50px 180px 70px;
}

.input-feilds-property {
  width: 100%;
  padding: 5px 10px;
  height: 34px;
  border: 1px solid #cccccc;
  font-size: 14px;
}
.text-feilds-property {
  width: 100%;
  padding: 5px 10px;
  height: 150px;
  border: 1px solid #cccccc;
}
.input-feilds-address {
  width: 92%;
  padding: 5px 10px;
  border: 1px solid #cccccc;
}
.titles-property {
  margin: 10px 0px;
}
.border-property {
  padding-bottom: 25px;
}
.btn-div {
  text-align: right;
}
.btn-div-prev {
  text-align: left;
}
.btn-property {
  width: 30%;
}
.multiply {
  background-color: #f7f7f7;
  padding: 7.5px 15px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.multiply-input {
  width: 43%;
  padding: 5px 10px;
  border: 1px solid #cccccc;
}
.more-options {
  margin: 16px 0px;
  color: blue;
  text-decoration: underline;
}
.drop-zone {
  height: 250px;
  border: 1px dashed blue;
  background-color: white;
  overflow: scroll;
}
.drop-zone-inner {
  background-color: white;
  border: 0px;
  height: 100px;
  text-align: center;
  padding: 40px;
}
.tabs {
  color: black;
}

.customError {
  border: 1px solid #721c24;
}
.dollar-sign {
  background-color: #f7f7f7;
  padding: 7px 15px;
  border: 1px solid #cccccc;
  border-right: 0px;
}
.input-feilds-price {
  width: 90%;
  padding: 5px 10px;
  border: 1px solid #cccccc;
}
.border-product {
  border-bottom: 1px solid #dce0e0;
}
.text-editor {
  height: 300px;
}
.table-heading {
  background-color: #001f3f;
  color: white;
  padding: 5px 20px;
  border: 1px solid white;
}
.table-remove {
  padding: 5px 15px;
  border: 1px solid #cccccc;
  text-align: center;
  cursor: pointer;
}
.percentage-sign {
  background-color: #f7f7f7;
  padding: 7px 15px;
  border: 1px solid #cccccc;
  /* border-right: 0px; */
  border-left: 0px;
}

.input-feilds-discount {
  width: 86%;
  padding: 5px 10px;
  border: 1px solid #cccccc;
}
.padding {
  padding: 0px 7px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("./assets/images/icons/ic_calendar.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
#imagePreview {
  height: 100px;
  width: 100px;
  vertical-align: sub;
  border-radius: 50px;
  max-width: 100%;
}
#imagePreviewVendor {
  height: 100px;
  width: 100px;
  vertical-align: sub;
  border-radius: 50px;
  max-width: 100%;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.fas {
  color: red;
}
.alice-carousel__stage-item {
  /* width: 309.833px !important; */
  margin-right: 20px !important;
}

.explore-our-neighbours .alice-carousel__stage-item,
.newsfeed .alice-carousel__stage-item {
  /* width: 309.833px !important; */
  margin-right: 0px !important;
}
.pxp-prop-card-1-fig .pxp-cover {
  width: 280px !important;
}
.alice-carousel__prev-btn-item.__inactive {
  opacity: 0 !important;
}
.alice-carousel__next-btn-item.__inactive {
  opacity: 0 !important;
}
.alice-carousel__next-btn-item {
  background-image: url("./assets/images/ic_right.svg");
  background-repeat: no-repeat;
  background-size: 35px;
  background-position-y: center;
  background-position-x: center;
}
.alice-carousel__prev-btn-item {
  background-image: url("./assets/images/ic_left.svg");
  background-repeat: no-repeat;
  background-size: 35px;
  background-position-y: center;
  background-position-x: center;
  left: 50px;
}
[data-area="<"],
[data-area=">"] {
  display: none;
}
.viewPassword-login {
  position: absolute;
  right: 25px;
  bottom: 183px;
}
.password-forgotPass {
  position: absolute;
  right: 25px;
  bottom: 156px !important;
}
.confirmPass-forgotPass {
  position: absolute;
  right: 25px;
  bottom: 94px !important;
}
.confirmPass-userSignup {
  position: absolute;
  right: 25px;
  bottom: 105px;
}
.pass-userSignup {
  position: absolute;
  right: 25px;
  bottom: 167px;
}
.pass-vendorSignup {
  position: absolute;
  right: 20px;
  bottom: 27px;
}
.list-inline-item {
  position: relative;
}
.list-inline-item ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 150px;
  text-transform: uppercase;
  /* font-weight: 700; */
  background-color: transparent;
}
.list-inline-item:hover ul {
  display: block;
}
.pxp-header.fixed-top.pxp-animate .list-inline-item ul li a {
  color: white;
}

.pxp-header.fixed-top.pxp-full .list-inline-item ul li a {
  color: #333;
}
.pxp-header.fixed-top.pxp-animate.pxp-is-sticky .list-inline-item ul li a {
  color: #333;
}

.drop-zone-inner :hover {
  border: none !important;
}
.text-right {
  position: relative;
}
.profile_header_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  text-align: left;
  box-shadow: 0px 0px 3px #777;
}
.profile_header_dropdown_li {
  color: #000;
  font-size: 14px;
  padding: 4px 10px;
  cursor: pointer;
}

.pxp-nav > li > a {
  font-size: 14px;
  font-family: Condensed, sans-serif;
  color: black;
}

.pxp-hero-caption h1 {
  font-size: 69px;
  padding-bottom: 15px;
}

.pxp-hero-search .custom-select,
.pxp-hero-search .form-control.pxp-is-address {
  font-size: 16px;
  font-family: Condensed, sans-serif;
  color: #000000;
}

.pxp-section-h2 {
  color: #000000;
  font-size: 36px;
  padding-left: 10px;
  font-family: Condensed, sans-serif;
}

.pxp-text-light {
  color: #8c8c8c;
  font-family: Condensed, sans-serif;
  font-size: 16px;
  padding-top: 4px;
  padding-left: 10px;
}

.container-fluid.pxp-props-carousel-right.mt-100 {
  padding-left: 0 !important;
  margin-top: 30px !important;
}

.pxp-prop-card-1-details-title {
  font-size: 17px;
  font-family: Condensed, sans-serif;
}

.pxp-prop-card-1-details-price {
  font-size: 20px;
  font-family: Regular, sans-serif;
  padding: 7px 0 8px 0;
}

.pxp-prop-card-1-details-features {
  font-size: 12px;
  font-family: Condensed, sans-serif;
}

.pxp-text-light {
  color: #4d4d4d;
  font-size: 16px;
  font-family: Condensed, sans-serif;
}
.social-icons {
  margin-left: 5px;
}
.title {
  font-family: "Condensed", sans-serif;
  color: #fff;
  text-transform: uppercase;
}
.pxp-section {
  font-family: "Condensed", sans-serif;
  color: #000;
  font-size: 36px;
}
.pxp-prop-card-1-details-titles {
  font-size: 16px;
  font-family: "Light", sans-serif;
  color: #fff;
}
.pxp-prop-card-1-details-cta text-uppercase {
  font-family: "Condensed", sans-serif;
  color: #fff;
  font-size: 16px;
}
.modal-width {
  width: 350px;
}
.modal-content {
  width: 350px;
}

.modal-content .phone-number {
  padding: 0.8rem 4rem !important;
}

.modal-content .form-control {
  padding: 0.8rem 0.75rem !important;
}

/* .text-center{
  font-family: "Regular", sans-serif;
  color: #000;
  font-size: 18px;
} */
.modal-header {
  border-bottom: none;
  padding: 1px 15px 0px 0px;
}
.modal-body {
  padding: 10px 15px 0 15px;
}

.pxp-nav.list-inline {
  margin: 0 auto;
}
.pxp-hero-caption h1 {
  font-size: 75px;
}
.pxp-hero-caption .title {
  margin-bottom: 40px;
  font-family: "Light", sans-serif;
}
.pxp-hero-caption-select-location .title-select-location {
  text-decoration: underline;
  margin-bottom: 30px;
}
.pxp-hero-search select,
.form-control.pxp-is-address,
.pxp-p-search-status,
.pxp-is-address {
  color: #4d4d4d;
  font-family: Condensed;
  font-size: 15px;
}
.pxp-section {
  font-size: 40px;
}

.pxp-content {
  padding-bottom: 100px;
}

p.pxp-text-light {
  color: #333;
  font-size: 16px;
}
.pxp-prop-card-1-details-titles {
  font-size: 17px;
}
.pxp-primary-cta.text-uppercase.mt-4.mt-md-5.pxp-animate {
  margin-top: 10px !important;
}
.pxp-services-item-text-title {
  color: #000000;
  font-family: Condensed, sans-serif;
}
.pxp-services-item-text-sub {
  color: #4d4d4d;
}
.pxp-areas-1-item-details-area {
  color: #000;
}
.pxp-cta-1.pxp-cover.mt-100.pt-300 {
  margin-top: 50px !important;
}
.pxp-agents-1-item-details-name {
  font-size: 22px;
  font-family: Condensed;
}
.pxp-content-side-h2 {
  font-size: 21px;
  color: #000;
}
.pxp-footer {
  padding: 10px 0;
}
h1.pxp-page-header,
.pxp-blog-posts-carousel-1-caption-title,
pxp-blog-post-block h2,
.pxp-blog-post-blockquote,
.pxp-blog-post-block h2,
.pxp-agent-section h3 {
  color: #000000;
  font-size: 40px;
}

.pxp-agent-section h3 {
  font-size: 34px;
}

.user-full-details {
  padding: 15px 0px;
}

.user-full-details p,
.user-full-details a {
  padding: 5px 0px;
  font-family: Condensed, sans-serif;
}
.user-full-details a {
  font-size: 14px;
  display: block;
}

.user-full-details .quality p {
  font-size: 16px;
  color: #000;
}
.user-full-details .detail-info p {
  font-size: 14px;
  color: #666;
}
.user-full-details .detail-info.status-red p {
  color: #ea0000;
}

.scroll-btn {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 35px;
  right: 70px;
  font-size: 20px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.arrow-up {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -5px;
}

.pxp-agents-hero-search-form label,
.pxp-sp-kd-item-label {
  font-family: regular, sans-serif;
  color: #000000;
  font-size: 16px;
}
.pxp-footer-bottom a {
  color: #000;
}
.viewPassword-login {
  bottom: 167px;
}
.country-code-forgotPass {
  bottom: 169px;
}
.country-code-forgot-page {
  bottom: 125.75px;
}
.pi-200 {
  padding-left: 200px !important;
}

.newsfeed {
  margin-top: 70px;
}
.for-rent,
.explore-our-neighbours-heading {
  color: #000;
}
.dashboard-newsfeed-details-hidden {
  color: #4d4d4d;
  height: 45px !important;
  overflow: hidden;
}
.dashboard-newsfeed-details-shown {
  color: #4d4d4d;
  height: auto;
  overflow: visible;
}
.suggested-vendor-name p {
  color: #000;
}
.suggested-vendor-follow a {
  color: #00b0e9;
}
/*index page */
main {
  background: #fafafa;
}
.add-property-conatiner {
  background-color: #fff;
}
.titles-property {
  color: #000;
}
.add-property-conatiner .nav-item .nav-link {
  background-color: none !important;
  color: #fff;
  border-bottom: 2px solid #00b0e9;
  width: auto;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0;
  text-align: center;
  padding-bottom: 0;
}
h6.titles-property {
  font-family: condensed, sans-serif;
  color: #000000;
  font-size: 16px;
}
h6.text-danger.titles-property {
  font-size: 12px;
}
h1.titles-property {
  font-family: condensed, sans-serif;
  color: #000000;
  font-size: 37px;
}
.btn-div .btn-property {
  background: #00b0e9;
  border-radius: 3px;
  font-size: 16px;
}

.pxp-hero-opacity-select-location {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.property-details {
  font-weight: bold;
}
.send-btn-single-property {
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 10px;
}
.comment-send-btn {
  position: relative;
  width: 100%;
}

.user-Profile {
  border-radius: 50% !important;
}

.map-pointer {
  background-image: url("./assets/images/ic_address.svg");
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}
.error-text {
  font-size: 12px;
  padding: 0px 10px;
}
.property-plan-div {
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0px;
}
.rightImages img {
  float: left;
  width: 50%;
}
.rightImages img, .leftBigImage img{
  max-width: 100%;
}
@media screen and (max-width: 485px){
  
}

@media screen and (max-width: 768px){
  .sliderGif {
    width: 100%;
  }
  .three-dots-icon
{
  margin-top: 20px;
  margin-left: 200px;
  justify-content: right;
}
}

.sliderGif {
  margin: auto;
  width: 50%;
  display: block;
}




/* dropdown css */

.three-dots-icon
{
  margin-top: 20px;
  margin-left: 240px;
  justify-content: right;
}

.modal-reports {
  cursor: pointer;
}
.modal-reports:hover {
  background-color: #878f97;
}